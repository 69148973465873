import React from "react"

import Layout from "../components/flayout";
import ClientType from "../components/clientType";

const Page = ({pageContext,data}) => (
  <Layout seoFields={{...seoFields, title: data.content.edges[0].node.clientTypeName}}>
    <ClientType clientType={pageContext.clientType} data={data} content={data.content.edges[0].node}/>
  </Layout>
)

const seoFields = {
  description: "",
  // lang,
  // meta,
  // keywords,
  // title: "About Us",
}

export const reviewClientTypeQuery = graphql`
query clientPageQuery($reviewId: Int!, $clientType: String!) {
  categReviews:
    allWordpressWpReview(
      filter: { review_cat: {eq: $reviewId}}
    ){
      edges {
        node {
          id
          title
          acf {
            featured
          }
          excerpt
          review_cat
          content
          date
        }
      }
    }
  featuredReviews: allWordpressWpReview(
      filter: { review_cat: {ne: $reviewId}, acf: { featured: {eq: true}}}
    ){
      edges {
        node {
          id
          title
          acf {
            featured
          }
          excerpt
          review_cat
          content
          date
        }
      }
    }
  content: allContentJson(filter:{clientType:{eq:$clientType}}) {
    edges {
      node {
        clientType
        clientTypeName
        banner {
          headline
          button
          text
          link
          tier
        }
        features {
          order
          headline
          bullets
          graphic
        }
        logos
      }
    }
  }
}
`

export default Page
