import React from "react"
import { StaticQuery, graphql } from "gatsby"
import LogoItem from "../logoItem";

const ClientTypeLogos = ({logos}) => (
  <StaticQuery
    query={graphql`
      query {
        jm_financial: file(relativePath: { eq: "logos/JM Financial.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        capitalmind: file(relativePath: { eq: "logos/Capitalmind.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        trade_smart: file(relativePath: { eq: "logos/Trade Smart.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        cgs_cimb: file(relativePath: { eq: "logos/CGS CIMB.jpeg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        ambavat_jain: file(relativePath: { eq: "logos/Ambavat Jain.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bravisa_templetree: file(relativePath: { eq: "logos/Bravisa Templetree.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        kredent_group: file(relativePath: { eq: "logos/Kredent Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        lalkar: file(relativePath: { eq: "logos/Lalkar Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        lnb: file(relativePath: { eq: "logos/LNB Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        mangal_keshav: file(relativePath: { eq: "logos/Mangal Keshav.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        nangia_advisors: file(relativePath: { eq: "logos/Nangia Advisors.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        oswal_securities: file(relativePath: { eq: "logos/Oswal Securities.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        pidilite: file(relativePath: { eq: "logos/Pidilite.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        shah_investors: file(relativePath: { eq: "logos/Shah Investors.PNG" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        samvitti_capital: file(relativePath: { eq: "logos/Samvitti Capital.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        shemaroo_group: file(relativePath: { eq: "logos/Shemaroo Group.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        vls_finance: file(relativePath: { eq: "logos/VLS Finance.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
        }
        d_kothary: file(relativePath: { eq: "logos/D Kothary.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        khimji_kunverji: file(relativePath: { eq: "logos/Khimji Kunverji.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bharat_forge: file(relativePath: { eq: "logos/Bharat Forge.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        shaman_group: file(relativePath: { eq: "logos/Shaman Group.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        barclays: file(relativePath: { eq: "logos/Barclays.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
        return (
            <>
              {
                logos.map((item,index) => {
                  return (
                      <div className={["col-md-3", "col-6", "text-center", "pt-3", "pb-3", "pt-md-5", "pb-md-5"].join(" ")} key={index}>
                        <LogoItem imgData={data[item]}/>
                      </div>
                  )
                })
              }
            </>
        )
    }}
  />
)
export default ClientTypeLogos