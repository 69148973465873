import React from "react"

import styles from "./index.module.css";
import ReviewsSlider from "../reviewsSlider";
import FeatureList from "../featureList";
import TryForFree from "../tryForFree";

import CaTopGraphic from "../../gatsby-images/ca-top-graphic";
import FamilyOfficesTopGraphic from "../../gatsby-images/family-offices-top-graphic";
import FinancialAdvisorsTopGraphic from "../../gatsby-images/financial-advisors-top-graphic";
import InvestorsTopGraphic from "../../gatsby-images/investors-top-graphic";
import { shuffleArray } from "../../common/gFunctions";
import ClientTypeLogos from "../clientTypeLogos";
import { Link } from "gatsby";
import GoogleReviewsSlider from "../googleReviewsSlider";

const TopGraphics = {
    'CharteredAccountants': <CaTopGraphic/>,
    'FamilyOffices': <FamilyOfficesTopGraphic/>,
    'FinancialAdvisors': <FinancialAdvisorsTopGraphic/>,
    'Investors': <InvestorsTopGraphic/>
}

class ClientType extends React.Component {

    constructor(props) {
        super(props);

        const data = this.props.data;
        var reviewsList = [];
        var featuredCategList = data.categReviews.edges.filter(x => x.node.acf.featured);
        reviewsList.push(...shuffleArray(featuredCategList.slice(0,15)));
        if (featuredCategList.length < 15) {
            var notFeaturedCategList = data.categReviews.edges.filter(x => !x.node.acf.featured);
            var remainingCount = 15 - featuredCategList.length;
            reviewsList.push(...shuffleArray(notFeaturedCategList.slice(0,remainingCount)));
        }

        if (reviewsList.length < 15) {
            var featuredList = data.featuredReviews.edges;
            remainingCount = 15 - reviewsList.length;
            reviewsList.push(...shuffleArray(featuredList.slice(0,remainingCount)));
        }

        this.state = {
            reviewsList
        }
    }

    render() {
        const {clientType,content} = this.props;

        const recommendedState = { tier: content.banner.tier };
        return (
            <div className={styles.client_main_section}>
                <div className={styles.mpr_title_section}>
                    <div className="container">
                        <div className={["row", "justify-content-md-center"].join(" ")}>
                            <div className={[styles.mpr_main_title ,'col-lg-10','text-center'].join(' ')}>
                                <h2 className="mb-3">{content.banner.headline}</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className={["col-md-9", "col-12", "text-center", "pt-0", "pb-4"].join(" ")}>
                                <p className={[styles.bannerText,"text-center"].join(" ")}>{content.banner.text}</p>
                                <Link className={["btn", "btn-primary", "rounded"].join(" ")} to={content.banner.link} state={recommendedState}>{content.banner.button}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.client_banner_section}>
                        <div className="container">
                            <div className="row">
                                <div className={["col-12", "pb-5"].join(" ")}>
                                    {
                                        TopGraphics[clientType]
                                    }
                                </div>
                                <div className={["col-12", "text-center", "pt-0", "pb-4"].join(" ")}>
                                    <h2>MProfit is trusted by</h2>
                                </div>
                            </div>
                        </div>
                </div>
                <div className={[styles.client_logo_section,''].join(' ')}>
                    <div className="container">
                        <div className="row">
                            <ClientTypeLogos logos={content.logos}/>
                        </div>
                    </div>
                </div>
                <div className={styles.features_section}>
                    {
                        content.features.map((feature,index) => <FeatureList feature={feature} imageOnRight={index%2===1} key={index}/> )
                    }
                </div>

                {/* <ReviewsSlider reviewsList={this.state.reviewsList}/> */}
                <GoogleReviewsSlider />

                <TryForFree isDesktop={content.banner.product < 300} recommendedState={recommendedState}/>
            </div>
        )
    }
}

export default ClientType
