import React from "react"
import Img from "gatsby-image"
import styles from "./styles.module.css";

const LogoItem = ({imgData,margin=true}) => (
    <div className={[styles.logoContainer,margin && styles.logoMargin].join(" ")}>
        <Img fluid={imgData.childImageSharp.fluid} imgStyle={{'objectFit': 'contain'}} className={styles.wrapper}/>
    </div>
)
export default LogoItem
